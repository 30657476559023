:root{
  --primary-background: #FFFFFF;
  --primary-header: #F3F6FB;
  --primary-header-hover: #E8EBEE;
  --primary-container: #F8FAFD;
  --primary-container-hover: #C1E7FE;
  --primary-buttons: #B7DAEF;
  --primary-buttons-hover: #B4D7ED;
  --primary-text: #1f1f1f;
  --border-radius: 24px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  max-height: 100%;
}

body {
  color: var(--primary-text);
  background-color: var(--primary-background);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  min-height: -webkit-fill-available;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

#root{
  min-height: 100vh;
  width: 100vw;
}

.app{
  padding-top: 5rem;
}

/* ...................................
              Header
................................... */

header{
  position: fixed;
  top: 0;
  left: 0;
  height: 5rem;
  width: 100vw;
  background-color: var(--primary-header);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0px 6px 15px -1px rgba(0,0,0,0.1);
}

header .logo{
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}

header .logo-image{
  width: 3rem;
  height: 3rem;
  color: red;
}

header .logo:hover{
  background-color: var(--primary-buttons-hover);
  cursor: pointer;
}

header .admin{
  width: 7rem;
  height: 3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  background-color: var(--primary-header-hover);
  border-radius: var(--border-radius);
}

header .admin:hover{
  background-color: var(--primary-buttons-hover);
  cursor: pointer;
}

header .admin:active,
header .logo:active{
  background-color: var(--primary-container-hover);
}

/* .......................................
              MainPage
....................................... */

.container{
  margin: 0 auto;
  width: 50vw;
  min-height: calc(100vh - 5rem - 6.5rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.post{
  width: 30rem;
  overflow: hidden;
  height: 100%;
  background-color: var(--primary-header-hover);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  font-size: .9rem;
  box-shadow: 3px 3px 15px -1px rgba(0,0,0,0.2);
}

.post img{
  height: auto;
  width: 100%;
  border-radius: var(--border-radius);
}

.post .tags{
  display: flex;
}

.post .tags .tag{
  margin: 0 1rem .5rem 0;
  padding: 0 .25rem;
  background-color: var(--primary-buttons-hover);
  border-radius: var(--border-radius);
  min-width: 2.5rem;
  text-align: center;
}

.post .post-info{
  padding: .5rem 1rem 1rem 1rem;
}

.post .post-info h1{
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: .5rem;
}

/* .......................................
              Submit content
....................................... */

.submit-form{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 20vh;
}

.submit-form input,
.submit-form button{
  margin-bottom: 1rem;
  height: 3rem;
  border-radius: var(--border-radius);
  border: 0px solid #fff;
  font-size: 1rem;
}

.upload-image{
  border-radius: 0!important;
}

.submit-form input{
  padding: .5rem;
  border: .1rem solid var(--primary-header-hover)
}

.submit-form input:focus{
  outline: none;
  border: .1rem solid var(--primary-buttons-hover)
}

.submit-form button{
  background-color: var(--primary-buttons);
}

.submit-form button:hover{
  background-color: var(--primary-buttons-hover);
  font-size: 1.05rem;
}

.post-image{
  width: 15rem;
  height: auto;
  margin-top: -15vh;
}

.delete-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .......................................
              Edit users
....................................... */

.user{
  display: flex;
  width: 25rem;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--primary-buttons);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.user i:hover{
  font-size: 1.25rem;
}

.add-user{
  display: flex;
  flex-flow: column;
  margin: 4rem 0 1rem 0;
}

.add-user > input{
  height: 3rem;
  padding: .5rem;
  border: .1rem solid var(--primary-header-hover);
  border-radius: var(--border-radius);
  border-radius: .7rem;
  font-size: 1rem;
  margin-top: .5rem;
}

.add-user > input:focus{
  outline: none;
  border: .1rem solid var(--primary-buttons-hover)
}

.add-user label{
  display: flex;
  padding: .5rem 0;
  align-items: center;
}

.add-user input[type=checkbox]{
  height: 1.5rem;
  width: 1.5rem;
  margin-left: .5rem;
}

.add-user button{
  height: 3rem;
  border-radius: var(--border-radius);
  border: 0px solid #000;
  font-size: 1rem;
  background-color: var(--primary-buttons);
  width: 10rem;
}

.add-user button:hover{
  background-color: var(--primary-buttons-hover);
  font-size: 1.05rem;
}

/* .......................................
              Edit tags
....................................... */
.edit-tags{
  width: 100%;
}

.edit-tags input{
  width: 75%;
  margin-bottom: 1rem;
}

/* .......................................
              Buttons
....................................... */

.back-btn{
  margin-top: 1rem;
  height: 2rem;
  font-size: 1rem;
  border: 0px solid #fff;
  border-radius: var(--border-radius);
  background-color: var(--primary-buttons);
  height: 3rem;
  width: 10rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-btn i{
  margin: 0 1.5rem;
}

.back-btn:hover,
.post-action button:hover{
  background-color: var(--primary-buttons-hover);
  font-size: 1.05rem;
}

button:active{
  background-color: var(--primary-container-hover);
}

.post-action{
  display: flex;
  justify-content: space-around;
}

.post-action button{
  height: 3rem;
  border: 0px solid #000;
  font-size: 1.1rem;
  background-color: var(--primary-buttons);
  width: calc(50% - 1px);
  display: flex;
  align-items: center;
}

.post-action button i{
  margin: 0 2rem 0 3rem;
}

/* .......................................
              Menu
....................................... */

.admin-menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 5vh;
}

.admin-menu button{
  margin-bottom: 1rem;
  height: 4rem;
  border-radius: var(--border-radius);
  border: 0px solid #000;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  background-color: var(--primary-buttons);
  width: 15rem;
}

.admin-menu button:hover{
  background-color: var(--primary-buttons-hover);
  font-size: 1.28rem;
  cursor: pointer;
}

.admin-menu button i {
  margin: 0 2rem;
}

/* .......................................
              Footer
....................................... */

footer{
  width: 100vw;
  background-color: var(--primary-header);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px -6px 15px -1px rgba(0,0,0,0.1);
}

footer .footer-socials{
  width: 15rem;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
}

footer .footer-socials a{
  background-color: var(--primary-header-hover);
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

footer .footer-socials a:hover{
  background-color: var(--primary-buttons-hover);
  text-decoration: none;
}

footer .footer-socials a:active{
  background-color: var(--primary-container-hover);
}

footer .footer-info{
  margin-top: .5rem;
  font-size: .8rem;
}


/* .......................................
              Tags
....................................... */

.sorting,
.submit-form .tags{
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.sorting input,
.submit-form .tags input{
  margin-left: .5rem;
  height: 1px;
  width: 1px;
  position: absolute;
  left: -100vw;
  top: -100vh;
}

.sorting label,
.submit-form .tags label{
  border: .1rem solid var(--primary-header-hover);
  box-shadow: 2px 2px 7px -.25px rgba(0,0,0,0.2);
  background-color: var(--primary-header-hover);
  padding: .1rem .25rem;
  border-radius: var(--border-radius);
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.sorting .tag{
  margin: 0 1rem 1rem 0;
  min-width: 3rem;
}

.sorting label:hover,
.submit-form .tags label:hover{
  border: .1rem solid var(--primary-buttons-hover);
  box-shadow: 2px 2px 7px -.25px rgba(180, 215, 237, 0.75)
}

.sorting input:checked + label,
.submit-form .tags input:checked + label{
  color: var(--primary-text);
  background-color: var(--primary-buttons-hover);
  border: .1rem solid var(--primary-buttons-hover);
  box-shadow: 2px 2px 7px -.25px rgba(180, 215, 237, 0.75)
}



@media screen and (max-width: 890px) {
  .container{
    width: 80vw;
  }  
}

@media screen and (max-width: 550px){
  .post{
    width: 20rem;
  }
}

@media screen and (max-width: 420px){
  .container{
    width: 90vw;
  }
  .post{
    width: 19rem;
  }
  .user{
    width: 17rem;
  }
}